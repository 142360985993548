import CreateRoutes from "./routes";



function App() {
  return (
    <CreateRoutes />
  );
}

export default App;
