import React, { useEffect, Fragment } from "react";
import CarImage from "../../../../assets/images/image12.jpg";

import Aos from "aos";
import "aos/dist/aos.css";
import TwoSectionLoader from "../../../Elements/SkeletonLoader/TwoSectionLoader";

const DrivesRequirements = ({ data, loaded }) => {
  useEffect(() => {
    Aos.init(Aos);
  }, []);
  return (
    <>
      {loaded ? (
        <>
          {" "}
          {data && (
            <div className="relative my-14"
             
            >
              <div className="relative bg-gradient-to-l from-blue-violet-100 via-zinc-100 to-white  w-full py-14">
                <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex flex-col-reverse  md:flex-row items-center md:h-full my-0 md:my-auto ">
                  <div className="relative w-1/2" >
                    <div className="realtive">
                      <div
                        dangerouslySetInnerHTML={{ __html: data?.title }}
                        className=" bg-[#EEEDFF] text-blue-violet-500 font-semibold text-2xl px-8 py-3 rounded-full inline-block"
                      >
                        {/* { {data?.subheading} } */}
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: data?.description }}
                        className="text-black font-semibold text-4xl mt-8"
                      >
                        {/* { {data?.heading} } */}
                      </div>
                    </div>
                    <div className="flex flex-col w-full mt-8 space-y-4">
                      {data &&
                        data?.steps.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="realtive flex bg-white shadow-lg rounded-xl w-full px-6 py-5 gap-4"
                            >
                              <div className="bg-[#EEEDFF] text-blue-violet-500 text-base w-8 h-8 rounded-full flex items-center justify-center font-medium">
                                {index + 1}
                              </div>
                              <div className=" text-black text-xl font-medium">
                                {item?.heading}
                              </div>
                              <Fragment key={index}></Fragment>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="ml-auto"
                    data-aos="fade-left"
                    data-aos-duration="1500">
                    
                    <div className="xl:w-[700px] w-44 overflow-hidden ">
                      <img
                        src={(data && data?.image?.url) || CarImage}
                        alt=""
                        className="w-full h-full object-fill"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <TwoSectionLoader />
        </>
      )}
    </>
  );
};

export default DrivesRequirements;
