import React, {useEffect} from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const Fleet = ({ data }) => {
  useEffect(() => {
    Aos.init(Aos);
  }, []); 
  return (
    <>
      <div className="relative"
      data-aos="fade-up"
      data-aos-duration="3000"
      >
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex flex-col   items-center md:h-full my-0 md:my-auto  py-14">
          <div className="text-black text-4xl font-bold text-center mb-6 ">
            Our<span className="text-blue-violet-500 italic ml-2">Fleet</span>
          </div>
          <div className="relative space-x-8 flex w-full">
            {data &&
              data?.steps &&
              data?.steps.map((item, index) => (
                <div
                  key={item._id}
                  className="relative w-full  rounded-md shadow-md overflow-hidden  duration-300 hover"
                >
                  <div className="relative w-full">
                    <div className="w-full overflow-hidden h-52 ">
                      <img
                        src={item?.stepsImage?.url}
                        alt=""
                        className="w-full h-full object-cover "
                      />
                    </div>
                  </div>
                  <div className="relative w-full px-6 py-6">
                    <div className="text-black text-2xl font-bold flex flex-col md:leading-tight">
                      {item?.heading}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                      className="text-base leading-7 mt-4 text-slate-600"
                    ></div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Fleet;
