import React from "react";
import { Link } from "react-router-dom";
import BookPhoto from "../../../../assets/images/image18.png";
import TwoSectionLoader from "../../../Elements/SkeletonLoader/TwoSectionLoader";

const BookCar = ({ data, loaded }) => {
  return (
    <>
      {loaded ? (
        <div className="relative">
          <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex flex-col   items-center md:h-full my-0 md:my-auto  py-14">
            <div className="relative">
              <div className="flex w-full gap-12">
                <div
                  className="relative w-1/2 pr-12"
                  data-aos="fade-right"
                  data-aos-duration="3000"
                >
                  <div className="w-full h-[550px] overflow-hidden rounded-4xl">
                    <img
                      src={BookPhoto}
                      alt=""
                      className="w-full h-full object-fill"
                    />
                  </div>
                  <div className="relative flex bg-blue-violet-500 rounded-full px-6 py-3 items-center justify-center text-white gap-3 mx-24 -mt-12">
                    <div>
                      <Link to={data && data?.buttonLink1}>
                        {data && data?.buttonText1}
                      </Link>
                    </div>
                    <div className="bg-white text-blue-violet-500 w-14 h-14 rounded-full flex items-center justify-center text-xl">
                      <i class="fa-solid fa-arrow-right"></i>
                    </div>
                  </div>
                </div>
                <div
                  className="relative w-1/2 "
                  data-aos="fade-left"
                  data-aos-duration="3000"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data && data?.shortDescription,
                    }}
                    className=" bg-[#EEEDFF] text-blue-violet-500 font-semibold text-2xl px-8 py-3 rounded-full inline-block"
                  ></div>
                  <div className="flex flex-col mt-6">
                    <div
                      dangerouslySetInnerHTML={{ __html: data && data?.title }}
                      className="text-black text-4xl font-bold mb-2 "
                    ></div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data && data?.description,
                      }}
                      className="text-sm text-slate-500 font-normal"
                    ></div>
                  </div>
                  <div className="relative space-y-6 mt-8">
                    {data?.steps.map((item, index) => {
                      return (
                        <div className="flex gap-3" key={index}>
                          <div className="bg-[#EEEDFF] w-16 h-16 rounded-full text-blue-violet-500 text-3xl flex items-center justify-center shrink-0">
                            <i className={item?.icon}></i>
                          </div>
                          <div className="relative shrink">
                            <div className="text-xl font-semibold text-black">
                              {item?.heading}
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item?.description,
                              }}
                              className="text-lg text-slate-400"
                            ></div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <TwoSectionLoader />
      )}
    </>
  );
};

export default BookCar;
