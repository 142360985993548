import React from "react";
import BgImage from "../../../assets/images/image11.jpg";
import AppCallToAction from "../AppCallToAction";
import BannerLoader from "../../Elements/SkeletonLoader/BannerLoader";

const DownloadApp = ({ data, loaded }) => {
  return (
    <>
      {loaded ? (
        <>
          <div
            className="relative overflow-hidden w-full z-0 h-[350px] md:h-[400px] xl:h-[460px] flex items-center bg-white xl:mt-28 lg:mt-14"
            data-aos="fade-down"
            data-aos-duration="3000"
          >
            <div className="absolute top-0 left-0 w-full h-full z-[-1] after:absolute after:content-[''] after:w-full after:h-full after:top-0 after:left-0 after:bg-black/85 after:via-50%">
              <img src={BgImage} className="w-full h-full object-cover" />
            </div>
            <div className="relative z-[1] w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex items-center ">
              <div className="w-full md:px-20 xl:px-0 xl:w-3/5 mx-auto text-center flex flex-col">
                <div
                  dangerouslySetInnerHTML={{ __html: data && data?.title }}
                  className="font-Lexend text-2xl sm:text-4xl xl:text-5xl font-bold text-white leading-tight"
                ></div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data && data?.description,
                  }}
                  className="text-sm md:text-xl font-medium leading-6 md:leading-8 text-white mt-4 2xl:px-9 3xl:px-20"
                ></div>
                <div className="mt-5 md:mt-10 mx-auto">
                  <AppCallToAction data={data && data} />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <BannerLoader />
      )}
    </>
  );
};

export default DownloadApp;
