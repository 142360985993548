import React, { useEffect, useState } from "react";
import Input from "../components/form/Input";
import Textarea from "../components/form/Textarea";
import Button from "../components/form/Button";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import BASE_URL from "../config/host";
import { Link } from "react-router-dom";

const Contact = () => {
  const siteSettingsData = useSelector((state) => state?.siteSettings?.data);
  const PageData = useSelector((state) => state?.pageSlice?.data);
  const [formData, setformData] = useState({});
  const [loaded, setLoaded] = useState(true);
  const [loading, setLoading] = useState(true);
  let emailCheaker =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const contactPage =
    PageData &&
    Array.isArray(PageData) &&
    PageData.length > 0 &&
    PageData.find((elm) => elm?.shortDescription === "Contact");

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 3 seconds delay

    return () => clearTimeout(timer); // Cleanup function for the timer
  }, []);

  const handleSubmit = () => {
    if (!formData.name || formData.name == "") {
      toast.warning("Name is required!");
    } else if (
      !formData.email ||
      !emailCheaker.test(String(formData.email).toLowerCase())
    ) {
      toast.warning("Email is required!");
    } else if (!formData.phone || !formData.phone.match(/^\d{10}$/)) {
      toast.warning("Contact number is required!");
    } else if (!formData.query || formData.query == "") {
      toast.warning("Query is required!");
    } else {
      setLoaded(false);
      try {
        axios
          .post(`${BASE_URL}/enquiry/contact`, {
            ...formData,
            enquiryType: "contact",
          })
          .then((res) => {
            setLoaded(true);
            if (res?.data?.status === 201) {
              toast.success(res?.data?.message);
              setformData({ name: "", email: "", phone: "", query: "" });
            }
          });
      } catch (error) {
        setLoaded(true);
        console.log(error, "contact error");
      }

      // toast.success("Your query is submitted successfully!");
      // setformData({ name: "", email: "", phone: "", query: "" });
    }
  };

  return (
    <section className="relative w-full py-20">
      <div className="w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto ">
        <div className="grid grid-cols-12 gap-20">
          {!loading ? (
            <>
              {" "}
              <div className="col-span-7">
                <div
                  dangerouslySetInnerHTML={{
                    __html: contactPage && contactPage?.title,
                  }}
                  className="font-bold text-5xl mb-6"
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: contactPage && contactPage?.description,
                  }}
                  className="leading-relaxed"
                />
                <div className="social space-y-3 mt-5 justify-center">
                  <a
                    className="flex justify-start gap-3 items-center"
                    href="#"
                    target="_blank"
                  >
                    <div className="text-blue-violet-500 text-sm lg:text-base -mt-0.5 p-3 bg-white shadow-lg rounded-md">
                      <i className="fa-solid fa-phone"></i>
                    </div>
                    <div className="text-slate-950 text-sm lg:text-base !leading-tighter transition-all duration-200">
                      {siteSettingsData && siteSettingsData?.phone}
                    </div>
                  </a>
                  <a
                    className="flex justify-start gap-3 items-center"
                    href="#"
                    target="_blank"
                  >
                    <div className="text-blue-violet-500 text-sm lg:text-base -mt-0.5 p-3 bg-white shadow-lg rounded-md">
                      <i className="fa-solid fa-envelope"></i>
                    </div>
                    <div className="text-slate-950 text-sm lg:text-base !leading-tighter transition-all duration-200">
                      {siteSettingsData && siteSettingsData?.email}
                    </div>
                  </a>
                  <a
                    className="flex justify-start gap-3 items-center"
                    href="#"
                    target="_blank"
                  >
                    <div className="text-blue-violet-500 text-sm lg:text-base -mt-0.5 p-3 bg-white shadow-lg rounded-md">
                      <i className="fa-solid fa-location-dot"></i>
                    </div>
                    <div className="text-slate-950 text-sm lg:text-base !leading-tighter transition-all duration-200">
                      {siteSettingsData && siteSettingsData?.address?.address1}
                    </div>
                  </a>
                </div>
                <div className="mt-9">
                  <h4 className="font-semibold text-1xl mb-2">Get In Touch</h4>
                  <div className="flex items-center gap-5">
                    {siteSettingsData &&
                      siteSettingsData?.social &&
                      Array.isArray(siteSettingsData?.social) &&
                      siteSettingsData?.social.length > 0 &&
                      siteSettingsData?.social.map((elm, index) => (
                        <Link
                          className="text-2xl hover:text-blue-violet-600 drop-shadow-xl"
                          to={elm?.url}
                          key={index}
                          target="_blank"
                        >
                          <i className={`${elm?.icon}`}></i>
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                role="status"
                className=" col-span-7  p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
              >
                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2.5"></div>
                <div className="w-48 h-2 mb-10 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div className="flex items-baseline mt-4">
                  <div className="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700"></div>
                  <div className="w-full h-56 ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
                  <div className="w-full bg-gray-200 rounded-t-lg h-72 ms-6 dark:bg-gray-700"></div>
                  <div className="w-full h-64 ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
                  <div className="w-full bg-gray-200 rounded-t-lg h-80 ms-6 dark:bg-gray-700"></div>
                  <div className="w-full bg-gray-200 rounded-t-lg h-72 ms-6 dark:bg-gray-700"></div>
                  <div className="w-full bg-gray-200 rounded-t-lg h-80 ms-6 dark:bg-gray-700"></div>
                </div>
                <span className="sr-only">Loading...</span>
              </div>
            </>
          )}

          <div className="col-span-5 bg-blue-violet-50 rounded-md pb-7 overflow-hidden shadow-xl">
            <h1 className="text-center font-semibold text-3xl text-white mb-6 bg-blue-violet-400 p-2">
              Contact With Us
            </h1>
            <form className="space-y-3 px-6">
              <div className="relative">
                <Input
                  label={"Name"}
                  inputName="name"
                  inputValue={formData?.name}
                  onChange={(e) =>
                    setformData((pre) => ({
                      ...pre,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  inputPlaceholder={"Enter your name"}
                  // errorType={formData.name ? "success" : "danger"}
                  // errorText={formData.name ? "Varified" : "Name is required!"}
                />
              </div>
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-6 relative">
                  <Input
                    label={"Email"}
                    inputName="email"
                    inputType={"email"}
                    inputValue={formData?.email}
                    inputPlaceholder={"Enter your email"}
                    // errorType={
                    //   !formData?.email
                    //     ? "danger"
                    //     : emailCheaker.test(
                    //         String(formData.email).toLowerCase()
                    //       )
                    //     ? "success"
                    //     : "warning"
                    // }
                    // errorText={
                    //   !formData?.email
                    //     ? "Email Id is required!"
                    //     : emailCheaker.test(
                    //         String(formData.email).toLowerCase()
                    //       )
                    //     ? "Varified!"
                    //     : "Please enter a valid email id!"
                    // }
                    onChange={(e) =>
                      setformData((pre) => ({
                        ...pre,
                        [e.target.name]: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="col-span-6 relative">
                  <Input
                    label={"Contact Number"}
                    inputType={"number"}
                    inputName="phone"
                    inputValue={formData?.phone}
                    // errorType={
                    //   !formData?.phone
                    //     ? "danger"
                    //     : formData.phone.match(/^\d{10}$/)
                    //     ? "success"
                    //     : "warning"
                    // }
                    // errorText={
                    //   !formData?.phone
                    //     ? "Contact number is required!"
                    //     : formData.phone.match(/^\d{10}$/)
                    //     ? "Varified!"
                    //     : "Please enter a valid phone number!"
                    // }
                    inputPlaceholder={"Enter your number"}
                    inputClasses={"remove-arrow"}
                    onChange={(e) =>
                      setformData((pre) => ({
                        ...pre,
                        [e.target.name]: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              {/* <div className="relative">
                <Input
                  label={"Company Name"}
                  inputPlaceholder={"Enter your company name"}
                />
              </div> */}
              <div className="relative">
                <Textarea
                  label={"Your Query"}
                  inputValue={formData?.query}
                  inputPlaceholder={"Your request/questions"}
                  inputClasses={"min-h-40"}
                  inputName="query"
                  // errorType={!formData.query ? "error" : "success"}
                  onChange={(e) =>
                    setformData((pre) => ({
                      ...pre,
                      [e.target.name]: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="relative">
                <Button
                  buttonType={"button"}
                  buttonLabel={"Submit"}
                  buttonClasses={"!px-4"}
                  buttonEffect={"filled"}
                  buttonFunction={handleSubmit}
                  isDisable={!loaded}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default Contact;
