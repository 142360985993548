import React, { useEffect, useState } from "react";
import Service from "../components/sections/Healthcare&Paratransit/OurServices/Service";
import ChooseGrand from "../components/sections/Healthcare&Paratransit/ChooseGrand/ChooseGrand";
import BookCar from "../components/sections/Common/BookCar/BookCar";
import ContactUs from "../components/sections/Common/ContactUs/ContactUs";
import HealthcareParatransitBanner from "../components/sections/Healthcare&Paratransit/Healthcare&ParatransitBanner/HealthcareParatransitBanner";
import MedicalAppointment from "../assets/images/image14.png";
import Rehabilitation from "../assets/images/image15.png";
import ParatransitServices from "../assets/images/image16.png";
import Specialized from "../assets/images/image17.png";
import { Link } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../config/host";
import { useSelector } from "react-redux";

const HealthcareParatransit = () => {
  const [allPageData, setAllPageData] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const siteSettingData = useSelector((state) => state?.siteSettings?.data);

  const GetStart = {
    subdata: [
      {
        _id: 1,
        icon: "fa-solid fa-shield-check",
        title: "Safety First",
        description:
          "Your safety is our top priority. We adhere to strict safety  protocols and regularly maintain our vehicles to ensure reliability and  peace of mind for our passengers.",
      },
      {
        _id: 2,
        icon: "fa-solid fa-person",
        title: "Accessibility",
        description:
          "We believe everyone deserves access to quality  transportation services. Our vehicles are designed to accommodate  passengers with varying mobility requirements.",
      },
      {
        _id: 3,
        icon: "fa-solid fa-wheelchair-move",
        title: "Reliability",
        description:
          "Count on Grand to be punctual and dependable. We  understand the importance of timeliness, especially when it comes to  medical appointments and healthcare-related travel",
      },
      {
        _id: 4,
        icon: "fa-solid fa-hand-holding-heart",
        title: "Compassionate Care",
        description:
          " Our drivers and staff members undergo  comprehensive training to provide compassionate and respectful  assistance to all passengers, ensuring a positive and comfortable  experience",
      },
    ],
  };

  const ourService = [
    {
      _id: 1,
      image: MedicalAppointment,
      heading: " Medical Appointment",
      headingAlt: "Transportation",
      description:
        "We understand the  importance of timely and stress-free transportation to medical  appointments. Our fleet of vehicles is equipped to accommodate  individuals with various medical needs, ensuring a comfortable journey to  and from healthcare facilities.",
    },
    {
      _id: 2,
      image: Rehabilitation,
      heading: " Rehabilitation",
      headingAlt: "Transport",
      description:
        "For individuals undergoing  rehabilitation programs, we offer tailored transportation solutions to  ensure seamless access to therapy sessions and rehabilitation centers",
    },
    {
      _id: 3,
      image: ParatransitServices,
      heading: "Paratransit Services",
      headingAlt: "Services",
      description:
        "Grand is committed to inclusivity and  accessibility for all individuals. Our paratransit services cater to  passengers with disabilities, providing door-to-door transportation and  assistance as needed",
    },
    {
      _id: 4,
      image: Specialized,
      heading: " Specialized",
      headingAlt: "Care",
      description:
        "Our trained and compassionate staff  members are experienced in providing specialized care during transit,  including wheelchair assistance, medical monitoring, and emotional  support",
    },
  ];

  const BookCarDetails = {
    title: "How To",
    titleAlt: "Book",
    subtitle: "Booking your elite luxury transportation with Grand is simple",

    steps: [
      {
        _id: 1,
        icon: "fa-light fa-globe",
        heading: "Online Reservation",
        description:
          " Choose Grand as your transportation providier. For Medicaid right please visit & sign up at:",
        Link: "https://www.medanswering.com ",
      },
      {
        _id: 2,
        icon: "fa-solid fa-phone",
        heading: "Phone Reservation",
        description:
          "Prefer to speak with a representative? Give a call to MedAnswering if you are using Medicaid at:  866- 932-7740 and request Grand as your transportation provider. Or you call us at 718-433-4255 so we can assist you",
      },
      {
        _id: 3,
        icon: "fa-solid fa-mobile",
        heading: " Mobile App",
        description:
          "Download our mobile app for quick and hassle-free  booking on the go. Manage your reservations, track your ride, and  receive real-time updates with ease.",
      },
    ],
  };

  const contactDeatail = {
    title: "Contact Us",
    subTitle:
      "Have questions or need assistance? Don't hesitate to reach out to our customer  service team.",
    btn: "Contact Us",
    heading:
      " Experience the Grand difference in healthcare and paratransit transportation. Trust us to get you where you need to go, safely and comfortably..",
    icon1: "fa-solid fa-phone",
    number: "718-433-4255",
    icon2: "fa-solid fa-envelope",
    email: "luxury@rydegrand.com",
    icon3: "fa-solid fa-location-dot",
    address: "36-13 32nd St LIC NY 11106",
  };

  let fetchPages = async () => {
    setLoaded(false);
    try {
      let data = await axios.get(`${BASE_URL}/pages`, {
        params: {
          limit: 100,
        },
      });
      if (data.status === 200) {
        setAllPageData(data?.data?.docs);
        setLoaded(true);
      }
    } catch (error) {
      setLoaded(true);
      console.log(error, "page error");
    }
  };
  useEffect(() => {
    fetchPages();
  }, []);
  // const serviceData =
  //   allPageData && allPageData.length > 0
  //     ? allPageData.filter(
  //         (elm) =>
  //           elm?.type === "healthcare-and-paratransit2" ||
  //           elm?.type === "healthcare-and-paratransit3" ||
  //           elm?.type === "healthcare-and-paratransit4" ||
  //           elm?.type === "healthcare-and-paratransit5"
  //       )
  //     : [];

  return (
    <>
      <HealthcareParatransitBanner
        data={
          allPageData &&
          allPageData.find((elm) => elm?.type === "healthcare-and-paratransit1")
        }
        loaded={loaded}
      />
      <Service
        // data={ourService}
        data={
          allPageData &&
          allPageData.find((elm) => elm?.type === "healthcare-and-paratransit2")
        }
        loaded={loaded}
      />
      <ChooseGrand
        // data={GetStart}
        data={
          allPageData &&
          allPageData.find((elm) => elm?.type === "healthcare-and-paratransit3")
        }
        loaded={loaded}
      />
      <BookCar
        // data={BookCarDetails}
        data={
          allPageData &&
          allPageData.find((elm) => elm?.type === "healthcare-and-paratransit4")
        }
        loaded={loaded}
      />
      <ContactUs
        data={contactDeatail}
        data1={siteSettingData}
        loaded={loaded}
      />
    </>
  );
};

export default HealthcareParatransit;
