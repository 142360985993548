import React, { useState } from "react";
import Accordian from "../Elements/Accordian";

const AccordionComponent = ({ data }) => {
  const [openAccordionId, setOpenAccordionId] = useState(null);

  const toggleAccordion = (id) => {
    setOpenAccordionId(openAccordionId === id ? null : id);
  };

  const driveAccordion = {
    title: "Why Drive with Grand?",
    subdata: [
      {
        _id: 1,
        title: "Flexible Schedule",
        descriptions: "Drive whenever it suits you and earn on your own terms.",
      },
      {
        _id: 2,
        title: "Flexible Schedule",
        descriptions: "Drive whenever it suits you and earn on your own terms.",
      },
      {
        _id: 3,
        title: "Flexible Schedule",
        descriptions: "Drive whenever it suits you and earn on your own terms.",
      },
    ],
  };

  const FaqData = {
    title: "Frequently Asked Questions",
    Faq: [
      {
        title: "Flexible Schedule",
        content: "Drive whenever it suits you and earn on your own terms.",
      },
      {
        title: "Competitive Earnings",
        content:
          "While employees might initially feel unsettled by succession planning, clear and open communication can alleviate these concerns. Emphasizing that succession planning is aimed at the company's stability and preparing employees for growth, rather than just job replacement, can change this perception. SuccessionNow provides businesses the ability to apply the centered approach, which includes the individual whos plans are being creating in the process, opening communication and employing empathy through their involvement.",
      },
      {
        title: "Dedicated Support",
        content:
          "SuccessionNow is adept at managing succession planning for various levels, from top management to entry-level positions. This flexibility is crucial to maintain operational continuity and ensure that all parts of the organization are considered in the planning process.",
      },
      {
        title: "Safety First",
        content:
          "The platform boasts a user-friendly interface, especially designed for non-technical staff. This ease of use stems from the extensive experience in succession planning by its co-founders, making it accessible and effective for anyone involved in succession planning.",
      },
    ],
  };

  return (
    <>
      <div className="relative pb-10">
        <div className="text-black font-medium text-base xl:text-xl mb-3 md:mb-5">
          Why Drive with Grand?
        </div>
        {data &&
          Array.isArray(data) &&
          data.length > 0 &&
          data.map((item, index) => (
            <Accordian
              key={index}
              id={index}
              title={item?.heading}
              isOpen={openAccordionId === index}
              toggleAccordion={toggleAccordion}
            >
              <div
                dangerouslySetInnerHTML={{ __html: item?.description }}
                className="text-sm md:text-base font-Inter text-zinc-500 py-2"
              >
                {/* {item?.description} */}
              </div>
            </Accordian>
          ))}
      </div>
    </>
  );
};

export default AccordionComponent;
