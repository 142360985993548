import React, { useState } from 'react'



const Accordian = ({title, children, id, isOpen, toggleAccordion }) => {

  return (
    <div className="lg:mb-3 bg-zinc-100 rounded-t mb-1">
      <div className="flex justify-between items-start px-4 py-2 bg-transparent cursor-pointer" onClick={() => toggleAccordion(id)}>
        <div className="text-base md:text-lg font-PlusJakartaSans font-medium text-slate-600 my-auto">{title}</div>
        <div className={`text-3xl text-dark-teal-500 transition-transform ${isOpen ? 'transform rotate-45' : ''}`}>&#43;</div>
      </div>
      <div className={`  overflow-hidden transition-max-height duration-500 px-4  xs:px-10 ${isOpen ? 'max-h-screen border-t border-zinc-300  px-4  ' : 'max-h-0  '}`}>
        {children}
      </div>
    </div>
  );
};

export default Accordian;