import React, { useEffect } from "react";
import BannerBackground from "../../../assets/images/image1.jpg";
// import SideBannerImage from "../../../assets/images/image2.jpg";
import MobileImage from "../../../assets/images/image3.png";
import AppCallToAction from "../AppCallToAction";
import Aos from "aos";
import "aos/dist/aos.css";
import BannerLoader from "../../Elements/SkeletonLoader/BannerLoader";

const HomeBanner = ({ data, loaded }) => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      {loaded ? (
        <div className="relative overflow-hidden w-full z-0 h-[600px] md:h-[500px] xl:h-[780px] flex items-center">
          <div className="relative overflow-hidden w-full h-full">
            <div className="absolute top-0 left-0 w-full h-full z-[-1]">
              <div className="relative h-full">
                <div className="w-full h-full overflow-hidden">
                  <img
                    src={BannerBackground}
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            </div>
            {/* <div className="absolute right-0 top-0">
              <div className="xl:w-[950px] lg:w-[580px] md:w-[500px] h-full overflow-hidden">
                <img
                  src={SideBannerImage}
                  className="w-full h-full object-content"
                />
              </div>
            </div> */}
            <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex flex-col-reverse md:flex-row items-center md:h-full my-0 md:my-auto">
              <div
                className="relative w-full md:w-2/4 md:pr-16 mt-4 md:mt-0"
                data-aos="fade-right"
                data-aos-duration="3000"
              >
                <div
                  // dangerouslySetInnerHTML={{ __html: data?.title }}
                  className="2xl:text-[42px] xl:text-3xl lg:text-xl md:text-md text-xl font-semibold text-white flex flex-col gap-0 "
                >
                  Where Every Ride is a{" "}
                  <span className="xl:text-5xl mt-3  2xl:text-7xl lg:text-4xl md:text-2xl text-4xl font-bold ">
                    Grand
                    <span className="italic text-blue-violet-500 ml-2">
                      Experience
                    </span>
                  </span>
                  {/* {data && data?.title} */}
                </div>

                <div className="relative lg:mt-6">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.description,
                    }}
                    className="text-white xl:text-2xl lg:text-xl xl:mt-8 2xl:pr-36 xl:pr-18 leading-1 md:leading-10 pl-6 mt-2 md:mt-0 after:absolute after:content[''] after:w-2 after:h-full after:bg-blue-violet-800 after:left-0 after:top-0"
                  >
                    {/* Choose the ride option that best suits your needs with Grand. */}
                    {/* {data && data?.description} */}
                  </div>
                </div>
                <div className="flex gap-3 items-center mt-4 md:mt-10">
                  <AppCallToAction data={data} />
                </div>
              </div>
              <div
                className="relative w-full flex mt-6"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <div className="xl:w-80 w-44 overflow-hidden mx-auto ">
                  <img
                    src={MobileImage}
                    className="w-full h-full object-contain"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <BannerLoader />
      )}
    </>
  );
};

export default HomeBanner;
